import { EventSchema } from "crud/eventsCRUD";
import { useParams } from "react-router-dom";
import { useApiQuery } from "./useAPI";
import { useEffect, useState } from "react";
import { setEventType } from "store/report/reportActions";
import { useDispatch } from "react-redux";

export type useCurrentEventReturnType = EventSchema | undefined;

export const useCurrentEvent = (): useCurrentEventReturnType => {
    const params = useParams<{ eventId: string }>();
    const [currentEvent, setCurrentEvent] = useState<EventSchema | undefined>(
        undefined,
    );

    const dispatch = useDispatch();

    let { data } = useApiQuery<EventSchema[]>("/events/report", ["events_report"], {
        staleTime: Infinity,
    });

    useEffect(() => {
        if (data) {
            const currentEvent = data!.find(
                (index) => index.id === params.eventId,
            );
            setCurrentEvent(currentEvent);
            dispatch(setEventType(currentEvent!.type));
        }
    }, [params.eventId, data, dispatch]);

    return currentEvent;
};
