import { COOKIE_SETTINGS_COOKIE_NAME } from "components/App/Permissions/CookieBanner/CookieBanner";
import { getCookie, parseCookie } from "utils/Cookies";
import { EventBundles } from "hooks/useAnalytics/eventTypes";
import { AccessManagementUser } from "types/auth";
import { useCallback } from "react";

export type useAnalyticsReturnType = {
    load: () => void;
    registerPageEvent: (pageName: string, location: string) => void;
    trackUserEvent: (event: EventBundles) => void;
    identify: (user: AccessManagementUser) => void;
};

export const useAnalytics = (): useAnalyticsReturnType => {
    const checkAnalyticsAllowed = (): boolean => {
        const cookieSettingsStr = getCookie(COOKIE_SETTINGS_COOKIE_NAME);
        if (cookieSettingsStr) {
            const settings = parseCookie(cookieSettingsStr);
            return settings.Analytics;
        }
        return false;
    };

    const load = useCallback((): void => {
        if (!checkAnalyticsAllowed()) {
            return;
        }

        if (!window.analytics || typeof window.analytics.load !== "function") {
            console.warn("Analytics is not available.");
            return;
        }
        window.analytics.load(`${import.meta.env.VITE_SEGMENT_TOKEN}`);
    }, []);

    const registerPageEvent = useCallback(
        (pageName: string, location: string): void => {
            if (!checkAnalyticsAllowed()) {
                return;
            }

            if (
                !window.analytics ||
                typeof window.analytics.page !== "function"
            ) {
                console.warn("Analytics is not available.");
                return;
            }
            window.analytics.page(pageName, { path: location });
        },
        [],
    );

    const trackUserEvent = useCallback((event: EventBundles): void => {
        if (!checkAnalyticsAllowed()) {
            return;
        }

        if (!window.analytics || typeof window.analytics.track !== "function") {
            console.warn("Analytics is not available.");
            return;
        }

        let traits = {};
        if (window.analytics && typeof window.analytics.user === "function") {
            //@ts-ignore
            traits = window.analytics.user().traits();
        }

        const allProperties = {
            ...event.payload,
            ...traits,
        };

        window.analytics.track(event.name, allProperties);
    }, []);

    const identify = useCallback((user: AccessManagementUser): void => {
        if (!checkAnalyticsAllowed()) {
            return;
        }

        if (
            !window.analytics ||
            typeof window.analytics.identify !== "function"
        ) {
            console.warn("Analytics is not available.");
            return;
        }

        window.analytics.identify(user.id, {
            company: user.company_name,
        });
    }, []);

    return { load, registerPageEvent, trackUserEvent, identify };
};
