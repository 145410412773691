import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import classes from "./Insights.module.css";

import { RootState } from "store/store";
import { PortfolioItem } from "store/user/userTypes";
import StatBar from "components/_Library/StatBar/StatBar";
import BasePage from "components/Pages/BasePage/BasePage";
import { EventSchema } from "crud/eventsCRUD";
import { LocationInsightsWrapper } from "./LocationInsightsWrapper";
import { Leaderboard } from "components/_Library/Leaderboard/Leaderboard";
import { InsightsBannerWrapper } from "./InsightsBannerWrapper/InsightsBannerWrapper";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

interface OwnProps {
    eventId?: string;
    selectedPortfolio: PortfolioItem;
    disableDownloads: boolean;
}

const LocationInsights: React.FC<OwnProps> = ({
    eventId,
    disableDownloads,
}: OwnProps) => {
    const insightsData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsData,
    );
    const isFetchingInsights: boolean = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").isFetchingInsights,
    );
    const allEventData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "service").eventData,
    );
    const event = allEventData.find(
        (event: EventSchema) => event.id === eventId,
    );
    const locationData = useSelector(
        (state) => getStoreAtNamespaceKey(state, "insights").locationData,
    );

    const { trackUserEvent } = useAnalytics();

    const renderBarCharts = () => {
        return (
            <div
                id={"barCharts"}
                className={cx(
                    classes.InsightsBarCharts,
                    classes.LocationInsightsBarCharts,
                )}
            >
                <div
                    className={cx(
                        classes.IndividualBar,
                        classes.LocationInsightsIndividualBar,
                    )}
                >
                    <StatBar
                        countLabel={"Exposure Layer Assessment"}
                        suffix={"Locations"}
                        barKeyOrientation={"portrait"}
                        data={
                            !insightsData?.insights
                                ? []
                                : insightsData?.insights?.exposure
                        }
                        tooltip={
                            "Chart presenting the breakdown of location count split by damage assessment for the Exposure Layer Assessment."
                        }
                        applyToAssessment={"exposure"}
                        onClick={(label) => {
                            trackUserEvent({
                                name: "bar_chart_segment_clicked",
                                payload: {
                                    event_id: event?.id!,
                                    event_name: event?.name!,
                                    source: "location",
                                    type: "exposure",
                                    assessment: label
                                }
                            })
                        }}
                    />
                </div>
                <div
                    className={cx(
                        classes.IndividualBar,
                        classes.LocationInsightsIndividualBar,
                    )}
                >
                    <StatBar
                        countLabel={"Claims Layer Assessment"}
                        suffix={"Locations"}
                        barKeyOrientation={"portrait"}
                        data={
                            !insightsData?.insights.claims
                                ? []
                                : insightsData?.insights?.claims
                        }
                        tooltip={
                            "Chart presenting the breakdown of location count split by damage assessment for the Claims Layer Assessments."
                        }
                        applyToAssessment={"claims"}
                        onClick={(label) => {
                            trackUserEvent({
                                name: "bar_chart_segment_clicked",
                                payload: {
                                    event_id: event?.id!,
                                    event_name: event?.name!,
                                    source: "location",
                                    type: "claims",
                                    assessment: label
                                }
                            })
                        }}
                    />
                </div>
            </div>
        );
    };

    let drivingAccountsLength = insightsData?.insights.drivers.length;
    return (
        <BasePage>
            {!isFetchingInsights && insightsData?.insights && locationData ? (
                <>
                    <div className={cx(classes.Container)}>
                        <div className={classes.InsightsContent}>
                            <div className={classes.InsightsTopBar}>
                                {renderBarCharts()}
                                <div className={classes.DrivingAccounts}>
                                    <Leaderboard
                                        title={"Top 10 Accounts"}
                                        data={insightsData.insights.drivers}
                                        helpTooltip={
                                            "List showing the count of locations within the Exposure Layer Assessment for top 10 insured names."
                                        }
                                        columns={
                                            drivingAccountsLength! <= 5 ? 1 : 2
                                        } // If length of data is 5 or below, we only need 1 column
                                        rows={5}
                                        noResultsMessage={
                                            "No accounts to display"
                                        }
                                    />
                                </div>
                            </div>
                            <LocationInsightsWrapper
                                event={event!}
                                loadingInsights={false}
                                insightsData={insightsData}
                                key={"location-insights"}
                                disableDownloads={disableDownloads}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <InsightsBannerWrapper />
            )}
        </BasePage>
    );
};

export default LocationInsights;
