import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import Dashboard from "components/Pages/Report/Dashboard/Dashboard";

import classes from "components/Pages/Report/Report.module.css";
import {
    setActiveTab,
    setDashboardView,
    setHighlightedLayer,
    setMapMarkerLocation,
    setReportIsPreview,
} from "../../../store/report/reportActions";
import { isDashboardView } from "utils/TypeGuards";
import { useApiQuery } from "hooks/useAPI";
import { ReportSchema } from "crud/reportsCRUD";

interface PreviewReportProps {
    previewReportId?: string;
}

interface DownloadsContextType {
    downloadModalOpen: boolean;
    setDownloadModalOpen: (open: boolean) => void;
    openLayerId: string | null;
    setOpenLayerId: (id: string | null) => void;
}
export const DownloadsContext = React.createContext<DownloadsContextType>({
    downloadModalOpen: false,
    setDownloadModalOpen: () => {},
    openLayerId: null,
    setOpenLayerId: () => {},
});

const Report: FC<PreviewReportProps> = ({
    previewReportId,
}: PreviewReportProps) => {
    const { eventId, page } = useParams<{
        eventId: string;
        page: string;
    }>();

    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [openLayerId, setOpenLayerId] = useState<string | null>(null);

    const dispatch = useDispatch();

    const { data } = useApiQuery<ReportSchema>(
        `/events/${eventId}/reports/by_latest`,
        ["report", eventId],
        {
            staleTime: Infinity,
            enabled: !previewReportId,
        },
    );

    React.useEffect(() => {
        dispatch(
            setDashboardView({
                view: page && isDashboardView(page) ? page : "report",
            }),
        );
        dispatch(setActiveTab("Layer"));
        dispatch(setHighlightedLayer(null));
        dispatch(setMapMarkerLocation(null));
        dispatch(setReportIsPreview(Boolean(previewReportId)));
    }, [dispatch, page, previewReportId]);

    return (
        <div className={classes.Container}>
            <DownloadsContext.Provider
                value={{
                    downloadModalOpen,
                    setDownloadModalOpen,
                    openLayerId,
                    setOpenLayerId,
                }}
            >
                <Dashboard
                    eventId={eventId}
                    reportId={previewReportId ?? data?.id}
                />
            </DownloadsContext.Provider>
        </div>
    );
};

export default Report;
