import { useOktaAuth } from "@okta/okta-react";
import axios, { AxiosRequestConfig, AxiosError } from "axios";
import {
    useMutation,
    useQuery,
    UseMutationOptions,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import {
    genericAPIResponse,
    genericPaginatedResponse,
    unsuccessfulAPIResponse,
} from "crud/genericResponse";
import { useDispatch } from "react-redux";
import { setAlert } from "store/system/systemActions";
import { useEffect, useRef } from "react";

const API_ROOT = import.meta.env.VITE_API_ROOT;

/**
 * Configures the API request with authentication and base URL.
 * @param token - The authentication token.
 * @param url - The endpoint URL.
 * @param config - Optional Axios request configuration.
 * @returns An object with the full URL and Axios configuration.
 */
const getApiConfig = (
    token: string,
    url: string,
    config?: AxiosRequestConfig,
) => ({
    fullUrl: `${API_ROOT}${url}`,
    axiosConfig: {
        ...config,
        headers: {
            ...config?.headers,
            Authorization: `Bearer ${token}`,
        },
    },
});

/**
 * Handles the API response, extracting data or throwing an error.
 * @template T - The type of the response data.
 * @param response - The API response object.
 * @returns The data from the API response.
 * @throws Error if the API request was unsuccessful.
 */
function handleApiResponse<T>(response: genericAPIResponse<T>): T {
    if (response.success) {
        return response.data;
    } else {
        throw new Error(response.message || "An error occurred");
    }
}

/**
 * Creates a human-readable error message.
 * @param error - The error object.
 * @returns A string representation of the error.
 */
const createHumanisedError = (error: Error): string => {
    return error.message || "An unknown error occurred";
};

/**
 * A custom hook for making API queries.
 * @template TData - The type of the response data.
 * @param url - The endpoint URL.
 * @param queryKey - The React Query cache key.
 * @param options - Additional options for the query, including Axios config.
 * @returns A React Query result object.
 *
 * @example
 * const { data, isLoading, error } = useApiQuery<UserData[]>(
 *   '/users',
 *   ['users'],
 *   {
 *     config: { params: { limit: 10 } },
 *     enabled: shouldFetchUsers
 *   }
 * );
 */
export function useApiQuery<TData = unknown>(
    url: string,
    queryKey: string[],
    options?: Omit<UseQueryOptions<TData, Error>, "queryKey" | "queryFn"> & {
        config?: AxiosRequestConfig;
    },
) {
    const { oktaAuth } = useOktaAuth();

    return useQuery<TData, Error>({
        queryKey,
        queryFn: () => {
            const token = oktaAuth.getAccessToken();
            const { fullUrl, axiosConfig } = getApiConfig(
                token!,
                url,
                options?.config,
            );
            return axios
                .get<genericAPIResponse<TData>>(fullUrl, axiosConfig)
                .then((res) => handleApiResponse(res.data))
                .catch((error: AxiosError<unsuccessfulAPIResponse>) => {
                    if (error.response?.data) {
                        throw new Error(
                            error.response.data.message || "An error occurred",
                        );
                    }
                    throw error;
                });
        },
        ...options,
    });
}

/**
 * A custom hook for making API mutations (POST, PUT, DELETE requests).
 * @template TData - The type of the response data.
 * @template TVariables - The type of the request body.
 * @param url - The endpoint URL.
 * @param method - The HTTP method ('POST', 'PUT', or 'DELETE').
 * @param options - Additional options for the mutation, including Axios config.
 * @returns A React Query mutation result object.
 *
 * @example
 * const createUser = useApiMutation<UserData, NewUserData>(
 *   '/users',
 *   'POST',
 *   {
 *     onSuccess: (data) => console.log('User created:', data),
 *     onError: (error) => console.error('Error creating user:', error)
 *   }
 * );
 *
 * Usage:
 * createUser.mutate({ name: 'John Doe', email: 'john@example.com' });
 */
export function useApiMutation<TData = unknown, TVariables = void>(
    url: string,
    method: "POST" | "PUT" | "DELETE",
    options?: Omit<
        UseMutationOptions<TData, Error, TVariables>,
        "mutationFn"
    > & {
        config?: AxiosRequestConfig;
    },
) {
    const { oktaAuth } = useOktaAuth();
    const token = oktaAuth.getAccessToken();
    const { fullUrl, axiosConfig } = getApiConfig(token!, url, options?.config);

    return useMutation<TData, Error, TVariables>({
        mutationFn: (variables) =>
            axios[method.toLowerCase() as "post" | "put" | "delete"]<
                genericAPIResponse<TData>
            >(fullUrl, variables, axiosConfig)
                .then((res) => handleApiResponse(res.data))
                .catch((error: AxiosError<unsuccessfulAPIResponse>) => {
                    if (error.response?.data) {
                        throw new Error(
                            error.response.data.message || "An error occurred",
                        );
                    }
                    throw error;
                }),
        ...options,
    });
}

/**
 * Options for customizing alerts and callbacks in API queries.
 * @template TData The type of successful response data
 * @template TError The type of error that can occur
 */
export interface AlertOptions<TData, TError> {
    /** Message to display on successful query */
    successMessage?: string;
    /** Message to display on query error */
    errorMessage?: string;
    /** Callback function to execute on successful query */
    onSuccess?: (data: TData) => void;
    /** Callback function to execute on query error */
    onError?: (error: TError) => void;
    /** Callback function to execute when the query is pending */
    onPending?: () => void;
}

/**
 * Parameters for the useApiQueryWithAlert hook.
 * @template TQueryFnData The type of data returned by the query function
 * @template TError The type of error that can occur
 * @template TData The type of transformed successful response data
 * @template TQueryKey The type of query key
 */
export interface UseApiQueryWithAlertParams<
    TQueryFnData,
    TError,
    TData = TQueryFnData,
    TQueryKey extends readonly unknown[] = unknown[],
> {
    /** The URL to query */
    url: string;
    /** The query key for React Query cache management */
    queryKey: TQueryKey;
    /** Additional options for React Query and Axios */
    options?: Omit<
        UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
        "queryKey" | "queryFn"
    > & {
        /** Axios request configuration */
        config?: AxiosRequestConfig;
    };
    /** Options for customizing alerts and callbacks */
    alertOptions?: AlertOptions<TData, TError>;
}

/**
 * A custom hook for making API queries with automatic alert handling.
 * @template TQueryFnData - The type of the raw response data.
 * @template TError - The type of error that can occur.
 * @template TData - The type of the processed response data.
 * @template TQueryKey - The type of the query key.
 * @param params - The parameters for the query and alert options.
 * @returns A React Query result object.
 *
 * @example
 * const { data, isLoading, error } = useApiQueryWithAlert<UserData[]>({
 *   url: '/users',
 *   queryKey: ['users'],
 *   options: {
 *     config: { params: { limit: 10 } },
 *     enabled: shouldFetchUsers
 *   },
 *   alertOptions: {
 *     successMessage: 'Users fetched successfully',
 *     errorMessage: 'Failed to fetch users',
 *     onSuccess: (data) => console.log('Fetched users:', data)
 *   }
 * });
 */
export function useApiQueryWithAlert<
    TQueryFnData = unknown,
    TError = unknown,
    TData = TQueryFnData,
    TQueryKey extends readonly unknown[] = unknown[],
>({
    url,
    queryKey,
    options,
    alertOptions,
}: UseApiQueryWithAlertParams<
    TQueryFnData,
    TError,
    TData,
    TQueryKey
>): UseQueryResult<TData, TError> {
    const dispatch = useDispatch();
    const onPendingCalled = useRef(false);
    const { oktaAuth } = useOktaAuth();
    const token = oktaAuth.getAccessToken();

    const query = useQuery<TQueryFnData, TError, TData, TQueryKey>({
        queryKey,
        queryFn: async () => {
            const { data } = await axios.get(`${API_ROOT}${url}`, {
                headers: { Authorization: `Bearer ${token}` },
                ...options?.config,
            });
            return data.data;
        },
        ...options,
    });

    useEffect(() => {
        if (query.isPending && !onPendingCalled.current) {
            alertOptions?.onPending?.();
            onPendingCalled.current = true;
        } else if (query.isSuccess) {
            if (alertOptions?.successMessage) {
                dispatch(
                    setAlert({
                        message: alertOptions.successMessage,
                        type: "Success",
                        timeout: 5000,
                    }),
                );
            }
            alertOptions?.onSuccess?.(query.data);
            onPendingCalled.current = false;
        } else if (query.isError) {
            let axiosError = query.error as AxiosError<unsuccessfulAPIResponse>;
            const errorMessage =
                alertOptions?.errorMessage ||
                axiosError?.response?.data.message ||
                `Error: ${createHumanisedError(query.error as Error)}`;
            dispatch(
                setAlert({
                    message: `Error: ${errorMessage}`,
                    type: "Error",
                    timeout: 5000,
                }),
            );
            alertOptions?.onError?.(query.error);
            onPendingCalled.current = false;
        }
    }, [
        query.isPending,
        query.isSuccess,
        query.isError,
        query.data,
        query.error,
        dispatch,
        alertOptions,
    ]);

    return query;
}

/**
 * Options for mutations with alert functionality.
 */
export type MutationOptionsWithAlert<TData, TError, TVariables> = Omit<
    UseMutationOptions<TData, TError, TVariables>,
    "mutationFn"
> & {
    successMessage?: string;
    errorMessage?: string;
    config?: AxiosRequestConfig;
};

/**
 * Parameters for the useApiMutationWithAlert hook.
 */
export interface UseApiMutationWithAlertParams<
    TData = unknown,
    TError = Error,
    TVariables = void,
> {
    url: string | ((variables: TVariables) => string);
    method: "POST" | "PUT" | "DELETE";
    options?: MutationOptionsWithAlert<TData, TError, TVariables>;
}

/**
 * @example
 * const createUser = useApiMutationWithAlert<UserData, Error, NewUserData>({
 *   url: '/users',
 *   method: 'POST',
 *   options: {
 *     successMessage: 'User created successfully',
 *     errorMessage: 'Failed to create user',
 *     onSuccess: (data) => console.log('Created user:', data),
 *     config: {
 *       params: { id: '123' },
 *       headers: { 'Custom-Header': 'value' }
 *     }
 *   }
 * });
 */
export function useApiMutationWithAlert<
    TData = unknown,
    TError = Error,
    TVariables = void,
>({
    url,
    method,
    options,
}: UseApiMutationWithAlertParams<TData, TError, TVariables>) {
    const dispatch = useDispatch();
    const { oktaAuth } = useOktaAuth();

    return useMutation<TData, TError, TVariables>({
        mutationFn: async (variables) => {
            const token = await oktaAuth.getAccessToken();
            const finalUrl = typeof url === "function" ? url(variables) : url;

            const config: AxiosRequestConfig = {
                ...options?.config,
                headers: {
                    ...options?.config?.headers,
                    Authorization: `Bearer ${token}`,
                },
                params: options?.config?.params,
            };

            let response;
            if (method === "DELETE") {
                response = await axios.delete<genericAPIResponse<TData>>(
                    `${API_ROOT}${finalUrl}`,
                    config,
                );
            } else {
                response = await axios[method.toLowerCase() as "post" | "put"]<
                    genericAPIResponse<TData>
                >(`${API_ROOT}${finalUrl}`, variables, config);
            }

            if (response.data.success) {
                return response.data.data!;
            } else {
                throw new Error(response.data.message || "An error occurred");
            }
        },
        onSuccess: (data, variables, context) => {
            if (options?.successMessage) {
                dispatch(
                    setAlert({
                        message: options.successMessage,
                        type: "Success",
                        timeout: 5000,
                    }),
                );
            }
            options?.onSuccess?.(data, variables, context);
        },
        onError: (error, variables, context) => {
            const axiosError = error as AxiosError<unsuccessfulAPIResponse>;
            const errorMessage =
                options?.errorMessage ||
                axiosError?.response?.data.message ||
                (error as Error).message ||
                "An unknown error occurred";
            dispatch(
                setAlert({
                    message: errorMessage,
                    type: "Error",
                    timeout: 5000,
                }),
            );
            options?.onError?.(error, variables, context);
        },
    });
}



/**
 * Parameters for the usePaginatedApiQueryWithAlert hook.
 * @template TData The type of data in the paginated response
 * @template TError The type of error that can occur
 * @template TQueryKey The type of query key
 */
export interface UsePaginatedApiQueryWithAlertParams<
    TData,
    TError,
    TQueryKey extends readonly unknown[] = unknown[]
> {
    /** The URL to query */
    url: string;
    /** The query key for React Query cache management */
    queryKey: TQueryKey;
    /** Additional options for React Query and Axios */
    options?: Omit<
        UseQueryOptions<genericPaginatedResponse<TData>, TError, genericPaginatedResponse<TData>, TQueryKey>,
        "queryKey" | "queryFn"
    > & {
        /** Axios request configuration */
        config?: AxiosRequestConfig;
    };
    /** Options for customizing alerts and callbacks */
    alertOptions?: PaginatedAlertOptions<TData, TError>;
}

/**
 * Options for customizing alerts and callbacks in API queries.
 * @template TData The type of successful response data
 * @template TError The type of error that can occur
 */
export interface PaginatedAlertOptions<TData, TError> {
    /** Message to display on successful query */
    successMessage?: string;
    /** Message to display on query error */
    errorMessage?: string;
    /** Callback function to execute on successful query */
    onSuccess?: (data: genericPaginatedResponse<TData>) => void;
    /** Callback function to execute on query error */
    onError?: (error: TError) => void;
    /** Callback function to execute when the query is pending */
    onPending?: () => void;
}



/**
 * A custom hook for making paginated API queries with automatic alert handling.
 * @template TData The type of data in the paginated response
 * @template TError The type of error that can occur
 * @template TQueryKey The type of query key
 * @param params - The parameters for the query and alert options.
 * @returns A React Query result object with genericPaginatedResponse<TData>.
 *
 * @example
 * const { data, isLoading, error } = usePaginatedApiQueryWithAlert<UserData, Error>({
 *   url: '/users',
 *   queryKey: ['users', page, limit],
 *   options: {
 *     config: { params: { page, limit } },
 *     enabled: shouldFetchUsers,
 *   },
 *   alertOptions: {
 *     successMessage: 'Users fetched successfully',
 *     errorMessage: 'Failed to fetch users',
 *     onSuccess: (data) => console.log('Fetched users:', data.data)
 *   }
 * });
 */
export function usePaginatedApiQueryWithAlert<
    TData = unknown,
    TError = unknown,
    TQueryKey extends readonly unknown[] = unknown[]
>({
    url,
    queryKey,
    options,
    alertOptions,
}: UsePaginatedApiQueryWithAlertParams<
    TData,
    TError,
    TQueryKey
>): UseQueryResult<genericPaginatedResponse<TData>, TError> {
    const dispatch = useDispatch();
    const onPendingCalled = useRef(false);
    const { oktaAuth } = useOktaAuth();

    const query = useQuery<genericPaginatedResponse<TData>, TError, genericPaginatedResponse<TData>, TQueryKey>({
        queryKey,
        queryFn: async () => {
            const token = oktaAuth.getAccessToken();
            const { fullUrl, axiosConfig } = getApiConfig(token!, url, options?.config);
            try {
                const response = await axios.get<genericPaginatedResponse<TData>>(fullUrl, axiosConfig);
                if (response.data.success) {
                    return response.data;
                } else {
                    throw new Error(response.data.message || "An error occurred");
                }
            } catch (error) {
                const axiosError = error as AxiosError<unsuccessfulAPIResponse>;
                if (axiosError.response?.data) {
                    throw new Error(axiosError.response.data.message || "An error occurred");
                }
                throw error;
            }
        },
        ...options,
    });

    useEffect(() => {
        if (query.isPending && !onPendingCalled.current) {
            alertOptions?.onPending?.();
            onPendingCalled.current = true;
        } else if (query.isSuccess) {
            if (alertOptions?.successMessage) {
                dispatch(
                    setAlert({
                        message: alertOptions.successMessage,
                        type: "Success",
                        timeout: 5000,
                    }),
                );
            }
            alertOptions?.onSuccess?.(query.data);
            onPendingCalled.current = false;
        } else if (query.isError) {
            const errorMessage =
                alertOptions?.errorMessage ||
                (query.error as Error).message ||
                "An unknown error occurred";
            dispatch(
                setAlert({
                    message: `Error: ${errorMessage}`,
                    type: "Error",
                    timeout: 5000,
                }),
            );
            alertOptions?.onError?.(query.error);
            onPendingCalled.current = false;
        }
    }, [
        query.isPending,
        query.isSuccess,
        query.isError,
        query.data,
        query.error,
        dispatch,
        alertOptions,
    ]);

    return query;
}