import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import { Modal, Stack } from "@mantine/core";

import { RootState } from "../../../../store/store";
import MapContainer from "../DashboardComponents/Map/Mapping/MapContainer/MapContainer";
import Summary from "components/Pages/Report/DashboardComponents/Summary/Summary";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";

import classes from "./Dashboard.module.css";
import {
    setDashboardView,
    setLayerConfig,
} from "../../../../store/report/reportActions";
import { ViewTabs } from "./ViewTabs/ViewTabs";
import ReportDownloads from "../Dashboard/Downloads/Downloads";
import { InsightsHeader } from "../DashboardComponents/Insights/InsightsHeader";
import { useApiQuery } from "hooks/useAPI";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useCurrentEvent } from "hooks/useCurrentEvent";
import { ReportMapSchema } from "crud/reportMapsCRUD";
import { useDisclosure } from "@mantine/hooks";
import ReportTexts from "../DashboardComponents/ReportText/ReportTexts";
import {
    LayersConfig,
    ReportTimelineSchemaBase,
} from "store/report/reportTypes";
import { DownloadsContext } from "../Report";
import { useLayerDownloadsAPI } from "crud/hooks/data_layers";
import { setAlert } from "store/system/systemActions";
import Downloads from "../Dashboard/Downloads/Downloads";
import { useInsightsLayerStylesAPI } from "crud/hooks/insight_layer_styles";
import { setInsightsStyles } from "store/insights/insightsActions";

interface DashboardProps {
    eventId: string | undefined;
    reportId?: string;
}

const Dashboard = ({ eventId, reportId }: DashboardProps) => {
    const [insightsViewed, setInsightsViewed] = useState(false);

    const [textModalOpened, { open: openTextModal, close: closeTextModal }] =
        useDisclosure(false);
    const [
        downloadsModalOpened,
        { close: closeDownloadsModal, toggle: toggleDownloadsModal },
    ] = useDisclosure(false);

    const {
        downloadModalOpen,
        setDownloadModalOpen,
        openLayerId,
        setOpenLayerId,
    } = useContext(DownloadsContext);

    const dispatch = useDispatch();
    const currentEvent = useCurrentEvent();
    const history = useHistory();
    // Prefetch layer downloads
    useLayerDownloadsAPI(reportId);

    // Prefetch insights layer styles
    const { data: insightsStyles } = useInsightsLayerStylesAPI();

    const { trackUserEvent } = useAnalytics();

    const dashboardView = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").dashboardView,
    );
    const insightsData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsData,
    );

    const isInsightsLoaded = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").isInsightsLoaded,
    );

    const insightsEventId = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "insights").eventId,
    );

    const insightsType = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsType,
    );

    const hasReportId = Boolean(reportId);

    const { data: mapData, isLoading: isMapLoading } =
        useApiQuery<ReportMapSchema>(
            `/events/reports/${reportId}/map`,
            ["reports", reportId!, "map"],
            {
                staleTime: Infinity,
                enabled: hasReportId,
            },
        );

    useEffect(() => {
        if (
            eventId === insightsEventId &&
            isInsightsLoaded &&
            Boolean(insightsData) &&
            dashboardView !== "insights" &&
            dashboardView !== "policy_insights" &&
            insightsType === null
        ) {
            dispatch(
                setAlert({
                    message: "Insights are available",
                    timeout: 5000,
                }),
            );
        }
    }, [
        isInsightsLoaded,
        dashboardView,
        insightsType,
        dispatch,
        insightsEventId,
        insightsData,
        eventId,
    ]);

    useEffect(() => {
        if (insightsStyles) {
            dispatch(setInsightsStyles(insightsStyles));
        }
    }, [dispatch, insightsStyles]);

    const toggleDownloadModal = useCallback(() => {
        setDownloadModalOpen(!downloadModalOpen);
    }, [downloadModalOpen, setDownloadModalOpen]);

    const { data: layersConfig, isLoading: isLayerConfigLoading } =
        useApiQuery<LayersConfig>(
            `/events/reports/${reportId}/layers/config`,
            ["reports", reportId!, "layersConfig"],
            {
                staleTime: Infinity,
                enabled: hasReportId,
            },
        );

    const generateDownloads = useCallback(() => {
        return <Downloads reportId={reportId!} layerId={openLayerId} />;
    }, [openLayerId, reportId]);

    const renderDownloadModal = useCallback(() => {
        return (
            <Modal.Root
                onClose={() => {
                    toggleDownloadModal();
                    setOpenLayerId(null);
                }}
                opened={downloadModalOpen}
                size="auto"
            >
                <Modal.Overlay />
                <Modal.Content
                    className={classes.noXScroll}
                    bg="var(--secondary-color)"
                >
                    <Modal.Header p="xs">
                        <Modal.CloseButton size="lg" mr="0" />
                    </Modal.Header>
                    <Modal.Body
                        mih="calc(min(60rem, 60vh))"
                        w="calc(min(40vw, 60rem))"
                        p="lg"
                    >
                        <Stack align="center" h="100%">
                            {generateDownloads()}
                        </Stack>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
        );
    }, [
        toggleDownloadModal,
        downloadModalOpen,
        generateDownloads,
        setOpenLayerId,
    ]);

    const timelineQuery = useApiQuery<ReportTimelineSchemaBase>(
        `/events/reports/${reportId}/timeline`,
        ["reports", reportId!, "timeline"],
        {
            staleTime: Infinity,
            enabled: hasReportId,
        },
    );

    useEffect(() => {
        if (layersConfig) {
            dispatch(setLayerConfig(layersConfig));
        }
    }, [dispatch, isLayerConfigLoading, layersConfig]);

    return (
        <div
            id="dashboard"
            className={cx(classes.AppGrid, {
                [classes.ReportGrid]: dashboardView === "report",
            })}
        >
            <Summary />
            <Modal
                opened={textModalOpened && hasReportId}
                onClose={closeTextModal}
                size={"75vh"}
                title="Text Reports"
            >
                <ReportTexts reportId={reportId!} />
            </Modal>
            <Modal
                opened={downloadsModalOpened && hasReportId}
                onClose={closeDownloadsModal}
                className={classes.DownloadContainer}
                size={"75vh"}
                title="Report Downloads"
            >
                <ReportDownloads reportId={reportId!} layerId={null} />
            </Modal>
            {downloadModalOpen && renderDownloadModal()}
            <div
                className={classes.Component}
                style={{
                    gridArea: "1 / 1 / 4 / 6",
                    display: dashboardView === "report" ? "initial" : "none",
                }}
                key={"map"}
            >
                <MapContainer
                    toggleDownloadModal={toggleDownloadsModal}
                    mapData={mapData}
                    isMapLoading={isMapLoading}
                    isLayersConfigLoading={isLayerConfigLoading}
                    timelineQuery={timelineQuery}
                    hasReportId={hasReportId}
                    eventInfo={currentEvent}
                />
            </div>
            <div
                className={classes.Component}
                style={{
                    gridArea: "1 / 1 / 5 / 6",
                    display: ["insights", "policy_insights"].includes(
                        dashboardView,
                    )
                        ? "initial"
                        : "none",
                }}
                key={"insights"}
            >
                <InsightsHeader eventId={eventId!} />
            </div>

            <ViewTabs
                onInsightsClick={() => {
                    if (currentEvent) {
                        trackUserEvent({
                            name: "insights_tab_clicked",
                            payload: {
                                event_id: currentEvent.id,
                                event_name: currentEvent.name,
                            },
                        });
                    }
                    dispatch(
                        setDashboardView({
                            view: "insights",
                            history,
                        }),
                    );
                    dispatch(setInsightsViewed(true));
                }}
                onMapClick={() => {
                    if (currentEvent) {
                        trackUserEvent({
                            name: "map_tab_clicked",
                            payload: {
                                event_id: currentEvent.id,
                                event_name: currentEvent.name,
                            },
                        });
                    }
                    dispatch(
                        setDashboardView({
                            view: "report",
                            history,
                        }),
                    );
                }}
                onReportClick={() => {
                    if (currentEvent) {
                        trackUserEvent({
                            name: "report_tab_clicked",
                            payload: {
                                event_id: currentEvent.id,
                                event_name: currentEvent.name,
                            },
                        });
                    }
                    openTextModal();
                }}
                mapActive={dashboardView === "report"}
                insightsActive={["insights", "policy_insights"].includes(
                    dashboardView,
                )}
                insightsAvailable={Boolean(insightsData) && !insightsViewed}
            />
        </div>
    );
};

export default Dashboard;
