/**
 * WorkInProgress component for dashboard with a random background colour.
 */
import React, { FC } from "react";
import classes from "./InsightsBanner.module.css";
import Icon from "@mdi/react";
import { mdiChartBar, mdiContentCopy, mdiLockOpenOutline } from "@mdi/js";
import ButterflyLogo from "../../../../_Library/LoadingScreen/ButterflyLogo/ButterflyLogo";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../../store/storeSelectors";
import { setAlert } from "../../../../../store/system/systemActions";
import Button from "../../../../_Library/Button/Button";

interface InsightsBannerProps {
    loading?: boolean;
    policy?: boolean;

}

const InsightsBanner: FC<InsightsBannerProps> = (
    props: InsightsBannerProps,
) => {
    const fetchingInsights = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").isFetchingInsights,
    );

    const isInsightsLoaded = useSelector(
        (state: RootState) => 
            getStoreAtNamespaceKey(state, "insights").isInsightsLoaded,
    )

    const insightsType = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsType,
    );
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user,
    );
    const dispatch = useDispatch();

    let insightsMessage = (upsell?: boolean) => {
        if ((props.policy && !user?.has_policy_access ) || upsell) {
            return (
                <>
                    <p>
                        Analysis against your relevant risk can be made
                        available here; should you provide MIS access to your
                        in-force portfolio locations.
                    </p>
                    <p>Reach out to find out more.</p>
                    <p>mail@mckenzieintelligence.com</p>
                    <div className={classes.ContactUs}>
                        <Button
                            onClick={() => {
                                const emailAddress: string =
                                    "mail@mckenzieintelligence.com";
                                navigator.clipboard.writeText(emailAddress);
                                dispatch(
                                    setAlert({
                                        message:
                                            emailAddress +
                                            " copied to clipboard",
                                        timeout: 4000,
                                        type: "Success",
                                    }),
                                );
                            }}
                        >
                            mail@mckenzieintelligence.com
                            <Icon
                                className={classes.CopyIcon}
                                path={mdiContentCopy}
                            />
                        </Button>
                    </div>
                </>
            );
        } else {
            return insightsType === "noAffectedProperties"
                ? "No exposed locations within the event footprint"
                : "Currently no Insights report exists for this event";
        }
    };

    return (
        <div className={classes.Container}>
            <div className={classes.Header}>
                <h1>{props.policy ? "Policy" : "Location"} Insights</h1>
                <div className={classes.LockIcon}>
                    <Icon
                        path={
                            user?.portfolios && user.portfolios.length > 0
                                ? mdiChartBar
                                : mdiLockOpenOutline
                        }
                    />
                </div>
            </div>
            <div className={classes.Content}>
                {user?.portfolios && user.portfolios.length > 0 ? (
                    <>
                        {!isInsightsLoaded || fetchingInsights || props.loading ? (
                            <>
                                <ButterflyLogo animated />
                                <p>Loading Insights...</p>
                            </>
                        ) : (
                            <>{insightsMessage()}</>
                        )}
                    </>
                ) : (
                    <>
                        {insightsMessage(true)}
                    </>
                )}
            </div>
        </div>
    );
};

export default InsightsBanner;
