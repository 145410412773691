/**
 * Holds the tab components and displays the currently active one.
 * All tabs are rendered to the tree (but not displayed) even when not active as we need the geocoder tab ref to stay alive for its portal.
 */

import React, { Component, ComponentClass, FunctionComponent } from "react";
import cx from "classnames";

import ReactTooltip from "react-tooltip";

import classes from "./TabbedMenu.module.css";
import Icon from "@mdi/react";
import { TabType } from "store/report/reportTypes";
import { connect, ConnectedComponent } from "react-redux";
import { setCenter, toggleMenu } from "store/report/reportActions";
import { bindActionCreators, Dispatch } from "redux";
import SectionRow from "../SectionRow/SectionRow";
import {
    mdiInformationOutline,
    mdiLayersTriple,
    mdiMagnify,
    mdiMenuUp,
} from "@mdi/js";
import withAnalytics, { withAnalyticsProps } from "../HOC/withAnalytics";
import withCurrentEvent, { withCurrentEventProps } from "../HOC/withCurrentEvent";

interface StateEnabled {
    tabs: TabConfig;
    buttons?: ButtonConfig;
    absoluteDrawer?: boolean;
    // This is a confusing way to handle component layout
    // and should be refactored to be more modular
    domPositions?: string; // KEY: M (menu button), T (tabs), B (buttons), ' ' (indicates a gap). Exclude letter where needed.
}

interface StoreEnabled extends StateEnabled {
    activeTab: TabType;
    setActiveTab: (tab: TabType) => object;
    open: boolean;
    toggleMenu: (open: boolean) => void;
}

export const isStoreEnabled = (
    property: StateEnabled | StoreEnabled,
): property is StoreEnabled => {
    return property.hasOwnProperty("open") !== undefined;
};

type OwnProps = StateEnabled | StoreEnabled;

interface StateProps {}

interface DispatchProps {
    setCenter: typeof setCenter;
}

type TabbedMenuProps = OwnProps & StateProps & DispatchProps & withCurrentEventProps & withAnalyticsProps;

interface TabbedMenuState {
    activeTab: string;
    open: boolean;
}

export type TabConfig = {
    [key in TabType]?: {
        name: string; // used for title and tooltip
        iconComponent: JSX.Element;
        component:
            | ConnectedComponent<any, any>
            | FunctionComponent
            | ComponentClass; // child component used for tab
        onNavigate?: () => void;
    };
};

export type ButtonConfig = {
    [key: string]: {
        name: string;
        iconComponent: JSX.Element;
        function: () => void;
    };
};

class TabbedMenu extends Component<TabbedMenuProps, TabbedMenuState> {
    constructor(props: TabbedMenuProps) {
        super(props);

        this.state = {
            activeTab: "L",
            open: true,
        };
    }

    setActiveTab = (tabName: TabType) => {
        let tabConfig = this.props.tabs[tabName];
        if (tabConfig?.onNavigate) {
            tabConfig.onNavigate();
        }

        if (isStoreEnabled(this.props) && this.props.open !== undefined) {
            this.props.setActiveTab(tabName);
        } else {
            this.setState((state: TabbedMenuState) => {
                return {
                    activeTab: tabName,
                    open: state.activeTab === tabName ? !state.open : true,
                };
            });
        }
    };

    toggleTab = () => {
        if (isStoreEnabled(this.props) && this.props.open !== undefined) {
            if (this.props.open) {
                this.props.analytics.trackUserEvent({
                    name: "data_layer_collapsed",
                    payload: {
                        event_id: this.props.currentEvent?.id!,
                        event_name: this.props.currentEvent?.name!,
                    }
                })
            } else {
                this.props.analytics.trackUserEvent({
                    name: "data_layer_expanded",
                    payload: {
                        event_id: this.props.currentEvent?.id!,
                        event_name: this.props.currentEvent?.name!,
                    }
                })
            }
            this.props.toggleMenu(!this.get("open"));
        }
        this.setState((state) => {
            return {
                activeTab:
                    state.activeTab === ""
                        ? Object.keys(this.props.tabs)[0]
                        : state.activeTab,
                open: !state.open,
            };
        });
    };

    get = (attribute: "open" | "activeTab") => {
        if (isStoreEnabled(this.props) && this.props.open !== undefined) {
            return this.props[attribute];
        } else if (attribute in this.state) {
            return this.state[attribute];
        }
    };

    // Render all tab panes into tab container body
    addTabPanes() {
        let activeTab = this.get("activeTab");
        return Object.keys(this.props.tabs).map((tabName) => {
            const tabOptions = this.props.tabs[tabName as TabType]!;
            return (
                <div
                    id={"tourid_" + tabName}
                    key={tabName + "Key"}
                    className={cx(classes.TabPane, {
                        [classes.TabPaneActive]: tabName === activeTab,
                    })}
                >
                    <div className={classes.TabBody}>
                        {React.createElement(
                            tabOptions.component,
                            // @ts-ignore
                            { toggleMenu: this.toggleTab },
                        )}
                    </div>
                </div>
            );
        });
    }

    render() {

        const tourId = "TabsSideDrawer";
        return (
            <div
                className={cx(classes.Container, {
                    [classes.Hide]: !this.state.open,
                })}
                id={tourId}
            >
                <div className={classes.TabsContainer}>
                    <SectionRow
                        items={[
                            {
                                label: "Search",
                                iconComponent: (
                                    <Icon path={mdiMagnify} size={1.5} />
                                ),
                                onClick: () => this.setActiveTab("Geocoder"),
                                active: this.get("activeTab") === "Geocoder",
                                id: "tourid_LayerSearch",
                            },
                            {
                                label: "Layers",
                                iconComponent: (
                                    <Icon path={mdiLayersTriple} size={1.5} />
                                ),
                                onClick: () => this.setActiveTab("Layer"),
                                active: this.get("activeTab") === "Layer",
                                id: "tourid_Layers",
                            },
                            {
                                label: "Details",
                                iconComponent: (
                                    <Icon
                                        path={mdiInformationOutline}
                                        size={1.5}
                                    />
                                ),
                                onClick: () => this.setActiveTab("Info"),
                                active: this.get("activeTab") === "Info",
                                id: "tourid_LayerInfo",
                            },
                        ]}
                    />
                </div>
                <div
                    className={cx(classes.TabPanes, {
                        [classes.Absolute]: this.props.absoluteDrawer,
                    })}
                    id={"TabBody"}
                >
                    <div
                        data-tip={
                            this.state.open
                                ? "Close side panel"
                                : "Open side panel"
                        }
                        data-for={"TabContainerToolTip"}
                        className={cx(classes.TabMenuToggle, {
                            [classes.Closed]: !this.state.open,
                        })}
                        onClick={() => {
                            this.toggleTab();
                        }}
                    >
                        <Icon path={mdiMenuUp} size={1.25} />
                        <ReactTooltip
                            id={"TabContainerToolTip"}
                            place={"left"}
                            effect={"solid"}
                        />
                    </div>
                    {this.addTabPanes()}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setCenter: bindActionCreators(setCenter, dispatch),
    toggleMenu: bindActionCreators(toggleMenu, dispatch),
});

export default connect(null, mapDispatchToProps)(withAnalytics(withCurrentEvent(TabbedMenu)));
