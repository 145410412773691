import React from "react";
import { MapRef, Popup } from "react-map-gl";
import { useDispatch } from "react-redux";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import { setAlert } from "store/system/systemActions";
import classes from "./MapContextMenu.module.css";
import ImageShareModal from "./ImageShareModal/ImageShareModal";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { useCurrentEvent } from "hooks/useCurrentEvent";

export interface ContextMenuProps {
    latitude: number;
    longitude: number;
    zoom: number;
    callOnClick: () => void;
    mapRef: React.RefObject<MapRef>;
}

export default function MapContextMenu({
    latitude,
    longitude,
    zoom,
    callOnClick,
}: ContextMenuProps) {
    const dispatch = useDispatch();

    const { trackUserEvent } = useAnalytics();

    const currentEvent  = useCurrentEvent();

    const [shareImageModalOpen, setShareImageModalOpen] = React.useState(false);

    const handleClick = async () => {
        callOnClick();
        await navigator.clipboard.writeText(`${latitude}, ${longitude}`);
        dispatch(setAlert({ message: "Copied to clipboard", timeout: 3000 }));
        trackUserEvent({
            name: "coordinates_captured",
            payload: {
                event_id: currentEvent?.id!,
                event_name: currentEvent?.name!,
                lat: latitude,
                long: longitude,
                zoom: zoom,
            }
        })
    };

    if (latitude && longitude) {
        return (
            <Popup
                closeButton={false}
                longitude={longitude}
                latitude={latitude}
                className={classes.MapContextMenu}
                anchor="top-left"
            >
                <div>
                    <div
                        className={classes.MapContextMenuItem}
                        onClick={handleClick}
                    >
                        {`${Math.round(latitude * 100000) / 100000}, ${
                            Math.round(longitude * 100000) / 100000
                        }`}
                        <Icon path={mdiContentCopy} size={1} />
                    </div>
                    {/* To re-enable after 15/08/24 release */}
                    {/* <div
                        className={classes.MapContextMenuItem}
                        onClick={() => setShareImageModalOpen(true)}
                    >
                        Share image URL
                        <IconShare3 size={"1.5rem"} />
                    </div> */}
                </div>
                <ImageShareModal
                    closeModal={() => {
                        setShareImageModalOpen(false);
                    }}
                    opened={shareImageModalOpen}
                    closeContextMenu={callOnClick}
                />
            </Popup>
        );
    }
    return null;
}
